import * as React from "react";
import "../styles/base.scss";
import BuildTime from "../components/BuildTime";
import { Helmet } from "react-helmet";
import WordCount from '../components/WordCount';
import ListChile from '../components/ListChile';
import CountryList from "../components/CountryList";

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Diccionario Ilógico - Chile</title>
      </Helmet>
      <main className="container">
        <aside>
          <div className="content">
            <div className="intro">
              <h1>Diccionario Ilógico</h1>
              <p>L@s ilógic@s nos enseñamos palabras y modismos</p>
            </div>

            <CountryList />

            <a className="cta" rel="noreferrer" target="_blank" href="https://forms.gle/y3Z5M4RmATH3TrS5A">Envía tu palabra favorita</a>


            <p className="updated">
              Listando <WordCount /> palabras -
              Actualizado el <BuildTime/>
            </p>
          </div>
        </aside>
        <article>
          <ListChile />
        </article>
      </main>
    </>
  );
};

export default IndexPage;
